<template>
  <div class="visit-detail" v-loading="loading">
    <div class="box">
      <div class="message">
        <div class="title">客户信息</div>
        <ul>
          <li v-for="(item, index) in customerData" :key="index">
            <div class="li-title">{{ item.title }}</div>
            <div class="li-content">{{ item.content }}</div>
          </li>
        </ul>
      </div>
      <div class="message visit">
        <div class="title">拜访信息</div>
        <ul>
          <li v-for="(item, index) in visitData" :key="index"
              :class="[item.title === '拜访目的' ? 'line' : item.title === '拜访情况描述' ? 'line' : item.title === '关联商机' ? 'line' : item.title === '拜访开始地点' ? 'twoColumns' : item.title === '拜访结束地点' ? 'twoColumns' : '', item.title === '关联商机' && item.content === '' ? 'hide' : '']">
            <div class="li-title">{{ item.title }}</div>
            <div class="li-content">
              <span v-if="item.title === '关联商机'" class="color-blue">{{ item.content }}</span>
              <span v-else v-html="item.content"></span>
            </div>
          </li>
        </ul>
      </div>
      <div class="detail-btn">
        <span class="return" @click="bankBtn">返回</span>
      </div>
    </div>
  </div>
</template>

<script>
import {business_type_info, company_company_detail} from "../../request/businessOpportunityPool";
import {timeFormat} from "@/utils/index.js"
export default {
  name: 'VisitDetail',
  data() {
    return {
      loading: true,
      dictDefaultProps: {
        label: "name",
        value: 'id'
      },
      grids:[
        {
          id:1,
          name:'测试1'
        },
        {
          id:2,
          name:'测试2'
        },
        {
          id:3,
          name:'测试3'
        }
      ],
      customerData: [
        {title: '所在地区', content: '云南省-普洱市-思茅区'}, {title: '所属网格', content: '昭阳区商务楼宇商企网格'}, {title: '企业名称', content: '7天连锁酒店（高新区昊万店）'},
        {title: '联系人', content: '张三丰'}, {title: '联系电话', content: '18822226666'}, {title: '商企经理', content: '张三丰（zhangsanfeng）'},
      ], // 客户信息
      visitData: [
        {title: '拜访目的', content: '主要是为了了解企业的经营情况等等...'}, {title: '拜访开始时间', content: '2024-02-09 10:00:00'},
        {title: '拜访开始地点', content: '云南省玉溪市红塔区明珠路96号'}, {title: '拜访结束时间', content: '2024-02-09 12:00:00'},
        {title: '拜访结束地点', content: '云南省玉溪市红塔区明珠路96号'},
        {title: '拜访情况描述', content: '这里是拜访情况描述这里是拜访情况描述这里是拜访情况描述这里是拜访情况描述这里是拜访情况描述这里是拜访情况描述这里是拜访情况描述这里是拜访情况描述这里是拜访情况描述这里是拜访情况描述这里是拜访情况描述这里是拜访情况描述这里是拜访情况描述这里是拜访情况描述这里是拜访情况描述'},
        {title: '关联商机', content: '发现一个新商机'},
      ] // 拜访信息
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    formatTimestamp(timestamp) {
      const date = new Date(timestamp * 1000);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月份从 0 开始，所以要加1
      const day = ('0' + date.getDate()).slice(-2);
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);
      const seconds = ('0' + date.getSeconds()).slice(-2);

      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    },
    getData(){
      this.$api.company_company_detail(this.$route.query.id).then(res=>{
        this.customerData[0].content = res.data.company.areaid;
        // this.customerData[1].content = res.data.company.grid_id
        let grid_id = res.data.company.grid_id
        this.customerData[2].content = res.data.company.company
        this.customerData[3].content = res.data.contact_people
        this.customerData[4].content = res.data.mobile
        this.customerData[5].content = res.data.company.truename

        this.visitData[0].content = res.data.purpose
        // this.visitData[1].content = res.data.location
        this.visitData[1].content = this.formatTimestamp(res.data.start_time)
        this.visitData[2].content = res.data.is_start_range === 1  ? res.data.start_location : res.data.start_location + '<span style="color: red;">（范围外）</span>';
        this.visitData[3].content = this.formatTimestamp(res.data.end_time)
        this.visitData[4].content = res.data.is_start_range === 1  ? res.data.end_location : res.data.end_location + '<span style="color: red;">（范围外）</span>';
        this.visitData[5].content = res.data.desc
        this.visitData[6].content = res.data.business

        this.$api.business_grid_info(res.data.company.areaid).then(res=>{ //根据地区获取所有的网格信息
          if (res.data){
            this.customerData[1].content = this.selectDictLabel(res.data, grid_id, this.dictDefaultProps)
          }else {
            this.customerData[1].content = '45'
          }
        })

        this.loading = false
      })
    },
    bankBtn(){
      this.$router.push('/businessVisit')
    }
  },
}
</script>

<style lang="scss" scoped>
.visit-detail {padding: 18px;height: 100%;box-sizing: border-box;
  .box {padding: 30px;height: 100%;box-sizing: border-box;background: #FFFFFF;}
  .message {margin-bottom: 30px;
    .title {position: relative;margin-bottom: 20px;font-size: 18px;line-height: 20px;
      &:before {position: absolute;left: -30px;top: 0;content: '';width: 4px;height: 20px;background-color: #3984F5;}
    }
    ul {display: flex;flex-wrap: wrap;border-top: 1px solid #DBE1E5;border-left: 1px solid #DBE1E5;}
    li {display: flex;width: 33.3333%;line-height: 22px;
      >div {display: flex;align-items: center;padding: 9px 20px 8px;border-right: 1px solid #DBE1E5;border-bottom: 1px solid #DBE1E5;box-sizing: border-box;align-items: center;}
    }
    .line {width: 100%;}
    .li-title {width: 120px;color: #444F57;background: #F6F7F9;}
    .li-content {flex: 1;}
    .color-blue {color: #3984F5;}

    &.visit {
      li {
        &.twoColumns {
          width: 66.6666%;
          }

        &.line {width: 100%;}
      }
    }
  }
  .detail-btn {display: flex;
    span {display: block;margin-right: 10px;width: 120px;height: 40px;line-height: 40px;text-align: center;border-radius: 4px;}
    .return {background: #EEF1F3;color: #6C7880;}
  }
}
</style>
